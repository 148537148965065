var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.importFramework && _vm.importTransition === 'detail')?_c('div',{staticClass:"section import-details"},[_c('div',{staticClass:"import-details__section"},[_c('h3',{staticClass:"subtitle is-size-3 has-text-weight-normal"},[_vm._v(" The following details were detected. ")]),_c('p',{staticClass:"is-size-6 has-text-weight-light"},[_vm._v(" If these details don't look correct, please verify your file is correct and import again. ")]),_c('ul',{staticClass:"is-size-6 detected-import-details"},[(_vm.detailsDetected.columns > 0)?_c('li',[_vm._m(0),_vm._v(" CaSS detected "),_c('b',[_vm._v(_vm._s(_vm.detailsDetected.columns))]),_vm._v(" columns ")]):_vm._e(),(_vm.detailsDetected.rows > 0)?_c('li',[_vm._m(1),_vm._v(" CaSS detected "),_c('b',[_vm._v(_vm._s(_vm.detailsDetected.rows))]),_vm._v(" rows ")]):_vm._e(),_c('li',[_vm._m(2),_vm._v(" CaSS detected "),_c('b',[_vm._v(_vm._s(_vm.detailsDetected.competencies))]),_vm._v(" competencies in the imported framework ")]),_c('li',[_vm._m(3),_vm._v(" CaSS detected a "),_c('b',[_vm._v(_vm._s(_vm.detailsDetected.fileType))]),_vm._v(" file type ")]),(_vm.detailsDetected.headers)?_c('li',[_vm._m(4),_c('b',[_vm._v("Header rows detected")])]):_vm._e()]),_c('div',{staticClass:"buttons is-right"},[_c('div',{staticClass:"button is-light is-small is-pulled-right is-dark is-outlined",on:{"click":_vm.cancelImport}},[_c('span',[_vm._v(" Cancel ")]),_vm._m(5)]),(_vm.importTransition === 'detail')?_c('div',{staticClass:"button is-small is-primary is-outlined",on:{"click":function($event){return _vm.$store.commit('app/importTransition', 'preview')}}},[_c('span',[_vm._v(" Accept Details & Review ")]),_vm._m(6)]):_vm._e()])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon has-text-success"},[_c('i',{staticClass:"fa fa-check-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon has-text-success"},[_c('i',{staticClass:"fa fa-check-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon has-text-success"},[_c('i',{staticClass:"fa fa-check-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon has-text-success"},[_c('i',{staticClass:"fa fa-check-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon has-text-success"},[_c('i',{staticClass:"fa fa-check-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"fa fa-times-circle"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"fas fa-arrow-right"})])
}]

export { render, staticRenderFns }